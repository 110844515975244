module.exports = {
  siteMetadata: {
    title: `SexEmulator – Build the Perfect Babe in this Hot Adult Video Game`,
    description: `Join SexEmulator today and start building the ultimate babe of your dreams Control your sexy character as you teach her different skills such as blowjobs, spanking, anal and more! Fuck and suck your way straight to the next level up and give her new abilities! Play SexEmulator, the hottest adult game ever!`,
    author: ``,
    lang: `en`,
    siteUrl: `https://lp1.sexemulator.com/`
  },
  plugins: [
    `gatsby-plugin-robots-txt`,
    `gatsby-plugin-react-helmet`,
    `gatsby-transformer-sharp`,
    `gatsby-plugin-sharp`,
    `gatsby-plugin-zopfli`,
    {
      resolve: `gatsby-plugin-google-tagmanager`,
      options: {
        id: "GTM-5TPW92T",
        includeInDevelopment: true,
        defaultDataLayer: {
          optimizeContainerId: "OPT-KWZDTVK",
          UA_ID: "UA-133595375-5"
        }
      }
    },
    {
      resolve: `gatsby-plugin-manifest`,
      options: {
        name: `Sexemulator`,
        short_name: `Sexemulator`,
        start_url: `/`,
        background_color: `#fff`,
        theme_color: `#fff`,
        display: "standalone",
        crossOrigin: `use-credentials`
      }
    },
    `gatsby-plugin-sass`,
    `gatsby-plugin-less`,
    `gatsby-plugin-styled-components`,
    `gatsby-plugin-nprogress`,
    `gatsby-plugin-offline`,
    `gatsby-plugin-netlify`,
    {
      resolve: `gatsby-plugin-canonical-urls`,
      options: {
        siteUrl: `https://lp1.sexemulator.com/`
      }
    },
    {
      resolve: `gatsby-source-filesystem`,
      options: {
        name: `images`,
        path: `${__dirname}/src/images`
      }
    },
    {
      resolve: `gatsby-plugin-intl`,
      options: {
        path: `${__dirname}/src/intl`,
        languages: [`en`],
        defaultLanguage: `en`,
        redirect: false,
        redirectComponent: require.resolve(`./src/components/redirect.js`)
      }
    },
    {
      resolve: `gatsby-plugin-advanced-sitemap`,
      options: {
        exclude: [
          `/dev-404-page`,
          `/404`,
          `/404.html`,
          `/offline-plugin-app-shell-fallback`
        ],
        createLinkInHead: true
      }
    }
  ]
};
