module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-5TPW92T","includeInDevelopment":true,"defaultDataLayer":{"type":"object","value":{"optimizeContainerId":"OPT-KWZDTVK","UA_ID":"UA-133595375-5"}}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Sexemulator","short_name":"Sexemulator","start_url":"/","background_color":"#fff","theme_color":"#fff","display":"standalone","crossOrigin":"use-credentials","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":null},
    },{
      plugin: require('../node_modules/gatsby-plugin-nprogress/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://lp1.sexemulator.com/"},
    },{
      plugin: require('../node_modules/gatsby-plugin-intl/gatsby-browser.js'),
      options: {"plugins":[],"path":"/builds/crakmedia-hydra/development/landings/sexemulator-landings/sexemulator-lp1/src/intl","languages":["en"],"defaultLanguage":"en","redirect":false,"redirectComponent":"/builds/crakmedia-hydra/development/landings/sexemulator-landings/sexemulator-lp1/src/components/redirect.js"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
